<template>
    <div class="version__update" > 
        <v-card-title  class="d-flex justify-space-between">
            <div>
                <v-btn
                    variant="flat"
                    @click="rout()"
                    class="mr-4"
                ><v-icon>mdi-chevron-left</v-icon></v-btn>
                Редактирование
            </div>
            <div class="d-flex justify-space-between">
                <v-btn
                    class=" mr-4 button-cancel"
                    color="red"
                    variant="flat"
                    @click="showDealog('version')"
                    style="width: 100px !important"
                >
                    Удалить
                </v-btn>
                <v-btn
                    class="button-dialog-accept"
                    style="width: 200px !important"  
                    @click="updateVersions"
                    variant="flat"
                >
                    Сохранить изменения
                </v-btn>
            </div>
        </v-card-title>
        <div class="d-flex justify-space-between version__update-boby">
            <div class="version__update-left">
                <v-container>
                    <div>
                        <h3>Обновления в версии</h3>
                    </div>
                        <v-form ref="validInfo" v-model="validInfo" validation>
                            <div v-for="(el) of  versions.versionItems" :key="el.id">
                                <v-card class="mt-5 px-2">
                                    <!-- <v-container class="d-flex justify-space-between align-center">
                                        <h4>Блок {{index}}</h4>
                                    </v-container> -->
                                    <v-container class="pt-6">
                                        <v-text-field
                                            label="Заголовок"
                                            counter="50"
                                            v-model="el.title"
                                            :rules ="checkHistory.title"
                                            variant="outlined"
                                            maxlength="50"
                                            class="mb-3"
                                        >
                                        </v-text-field>
                                        <v-text-field
                                            label="Описание"
                                            counter="100"
                                            v-model="el.description"
                                            :rules ="checkHistory.description"
                                            variant="outlined"
                                            maxlength="100"
                                            class="mb-3"
                                        >
                                        </v-text-field>
                                        <div>
                                            <v-file-input
                                                v-if="(typeof el.file !== 'string')"
                                                show-size
                                                v-model="el.file"
                                                :rules ="checkHistory.file"
                                                label="Иконка"
                                                variant="outlined"
                                                hint="Максимальный размер изображения 100 МБ"
                                                persistent-hint
                                            >
                                            </v-file-input>
                                            <div class="d-flex justify-space-between align-center" v-else >
                                                <div>
                                                    <img style="width: 80px; height: 80px;" :src="createImageURL(el.file)">
                                                 </div>
                                                <v-btn

                                                    class="button"
                                                    variant="flat"
                                                
                                                    @click="el.file = null"
                                                    style="width: 100px !important"
                                                    
                                                >
                                                    Изменить
                                                </v-btn>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-space-between">
                                            <div></div>
                                            <v-btn
                                                v-if="versions.versionItems.length > 1"
                                                class="button-cancel"
                                                variant="flat"
                                                @click="showDealog('item',el.itemId)"
                                            > 
                                                Удалить обновление
                                            </v-btn>
                                        </div>
                                    </v-container>
                                </v-card>
                            </div>
                        </v-form>
                    <div>
                        <v-btn
                            class="mt-5 button-action"
                            @click="addBlock"
                            variant="flat"
                        >   
                            Добавить обновление
                        </v-btn>
                    </div>
                </v-container>
            </div>
            <div class="version__update-right">
                <v-container>
                    <h3>Информация о версии</h3>
                    <v-form ref="version" v-model="valid" validation>
                        <div class="mt-5">
                            <v-select
                                :items="deviceType"
                                label="Платформа"
                                v-model="versions.deviceType"
                                variant="outlined"
                            ></v-select>
                            <v-text-field
                                label="Новая версия"
                                v-model="versions.versionNumber"
                                variant="outlined"
                            >
                            </v-text-field>
                            <v-switch
                                  label="Обязательное обновление"
                                v-model="versions.isRequired"
                            ></v-switch>
                        </div>
                    </v-form>
                </v-container>
            </div>
        </div>
        <div>
            <v-dialog
                v-model="dialog.checkDelete"
                persistent
                max-width="500"
            >   
                <!-- <template v-slot:activator ></template> -->
                <DialogCheckDelete  @no = "unShowDealog" @yes = "deleteLink"/>
            </v-dialog>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import DialogCheckDelete from '@/components/DialogCheckDelete.vue'
    export default {
        data () {
            return {
                versions : {},
                checkHistory : {
                    title: [v => !! v && v.length <= 50 || "Обязательное поле"],
                    description: [v => !!v && v.length <= 100 || "Обязательное поле"],
                    file: [value => value !== null || "Обязательное поле"],
                    platform : [v => !!v || "Обязательное поле"],
                    versionNumber : [v => !!v || "Обязательное поле"],
                },
                valid:true,
                validInfo:true,
                search: '',
                deviceType : ['ios', 'android'],
                dialog : {
                    checkDelete : false
                },
                id:"",
                idItem:"",
                deleteLink : {},
                img:[],
                deletePull : []
            } 
        },
        created() {
            this.getVersion ();
        },
        methods : {
            unShowDealog () {
                this.dialog.checkDelete = false;
            },
            showDealog (type, id) {

                this.dialog.checkDelete = true;

                if(type == "item") {
                    this.deleteLink = this.deleteBlock
                    this.idItem = id
                } else if (type == "version") {
                    this.deleteLink = this.deleteVersion
                }
            },
            getVersion () {
                this.id = this.$route.params.id;
                axios.get(this.$store.getters.apiAdminPanelV3 + '/version',{
                    headers: {
                        'authorization': this.$store.getters.adminPanelHeaderAuth
                    },
                    params: {
                        versionId: this.id
                    }
                })
                .then(res => {
                    this.versions = res.data
                    //window.console.log(this.versions)
                })
                .catch(err => {
                    this.snackbar.isOpen = true
                    this.snackbar.text = err.response.data || err.stack
                })
            },
            createImage(file) {
                let reader = new FileReader();
                let el = this
                reader.onload = (e) => {
                el.form.photo = e.target.files[0];      
                };
                reader.readAsDataURL(file);
            },
            deleteVersion () {
                axios.delete(this.$store.getters.apiAdminPanelV3 + '/version',
                {
                    headers: {
                        'authorization': this.$store.getters.adminPanelHeaderAuth
                    },
                    params: {
                        versionId: this.id
                    }
                }).catch(err => {
                    window.console.log(err)
                })
                this.$router.push({name:"versions"});
            },
            async updateVersions () {
                if ((await this.$refs.version.validate()).valid && (await this.$refs.validInfo.validate()).valid) {

                    if( this.deletePull.length > 0) {
                        this.deleteItem(this.deletePull[0]) 
                    }

                    const itemsNew = this.versions.versionItems.filter((v)=> v.itemId.length  < 10)
                    const items = this.versions.versionItems.filter((v)=> v.itemId.length  > 10)

                    window.console.log(itemsNew)
                    window.console.log(items)
                    
                    const vervionId = this.versions.versionId;

                    items.forEach(element => {
                        
                        let formDataItem = new FormData();

                        formDataItem.append('title',  element.title || '');
                        formDataItem.append('description', element.description || '');
                        formDataItem.append('file', element.file || '');
                        formDataItem.append('versionId', vervionId || '');
                        formDataItem.append('itemId', element.itemId || '');

                        axios.post(this.$store.getters.apiAdminPanelV3 + '/version/item', formDataItem,
                        {
                            headers: {
                                'authorization': this.$store.getters.adminPanelHeaderAuth
                            }
                        }).then(res => {
                            window.console.log(res.data)
                        })
                        .catch(err => {
                            this.snackbar.isOpen = true
                            this.snackbar.text = err.response.data || err.stack
                        })
                    });

                    itemsNew.forEach(element => {
                        
                        let formDataItem = new FormData();

                        formDataItem.append('title',  element.title || '');
                        formDataItem.append('description', element.description || '');
                        formDataItem.append('file', element.file || '');
                        formDataItem.append('versionId', vervionId || '');

                        axios.post(this.$store.getters.apiAdminPanelV3 + '/version/item', formDataItem,
                        {
                            headers: {
                                'authorization': this.$store.getters.adminPanelHeaderAuth
                            }
                        })

                        .then(res => {
                            window.console.log(res.data)
                        })
                        .catch(err => {
                            this.snackbar.isOpen = true
                            this.snackbar.text = err.response.data || err.stack
                        })
                    });

                    let formData = new FormData();

                    formData.append('deviceType',   this.versions.deviceType);
                    formData.append('versionNumber', this.versions.versionNumber || '');
                    formData.append('photo', this.versions.versionItems[0].file || '');
                    formData.append('versionId', this.versions.versionId || '');
                    formData.append('isRequired', this.versions.isRequired);

                    axios.post(this.$store.getters.apiAdminPanelV3 + '/version', formData,
                    {
                        headers: {
                            'authorization': this.$store.getters.adminPanelHeaderAuth
                        }
                    })

                    .then(res => {
                        window.console.log(res.data)
                    })
                    .catch(err => {
                        window.console.log(err)
                    })

                    this.$router.push({name:"versions"});
                } else {
                    window.console.log("Валидация не прошла")
                }
            },
            deleteItem (id) {
                axios.delete(this.$store.getters.apiAdminPanelV3 + '/version/item',{
                    headers: {
                        'authorization': this.$store.getters.adminPanelHeaderAuth
                    },
                    params: {
                        itemId: id
                    }
                })
                .catch(err => {
                    this.snackbar.isOpen = true
                    this.snackbar.text = err.response.data || err.stack
                })
                this.unShowDealog();
            },
            rout() {
                this.$router.go(-1)
                // if(this.$route.params.typeBack == "details") {
                //     this.$router.push({name:name , params: {id: params}});
                // }
                // this.$router.push({name:"historlayout"});
            },
            generateID (items) {

                let id = (~~(Math.random()*1e8)).toString(16);

                items.forEach(element => {
                    if(element.itemId == id) {
                       id = this.generateID(items);
                    }
                });

                return id
            },
            addBlock() {

                let oldlist = this.versions.versionItems.filter((v)=> v.itemId < 10);
                let id = "";

                if(oldlist.length > 0 ) {
                    id = this.generateID(this.versions.versionItems.filter((v)=> v.itemId < 10))
                } else {
                    id =  (~~(Math.random()*1e8)).toString(16);
                }

                this.versions.versionItems.push({     
                        itemId: id,
                        title : "",
                        description:"",
                        file: null,    
                    }
                )
            },
            deleteBlock() {

                if(this.idItem.length  < 10) {
                    this.versions.versionItems = this.versions.versionItems.filter((v)=> v.itemId != this.idItem)
                } else { 
                    let list = this.versions.versionItems.filter((v)=> v.itemId.length > 10)
                    if(list.length > 1) {
                        this.deleteItem(this.idItem) 
                        this.versions.versionItems = this.versions.versionItems.filter((v)=> v.itemId  != this.idItem)
                    } else {
                        this.deletePull.push(this.idItem)
                        this.versions.versionItems = this.versions.versionItems.filter((v)=> v.itemId  != this.idItem)
                    }
                }

                this.unShowDealog();
            },
        },
        components : {
            DialogCheckDelete,
        },
    }
</script>

<style lang="scss">
    .version__update {
        &-boby {
            max-width: 1125px;
        }
        &-left {
            min-width: 475px !important;
            width: 475px !important;
            h4 {
                font-weight: 400;
                font-size: 20px;
            }
        }
        &-right {
            min-width: 475px !important;
        }
        &-title {
            h2 {
                font-weight: 500;
                font-size: 20px;
                display: block;
            }
            button {
                margin-right: 22px;
            }
            margin-top: 7px;
            margin-bottom: 8px;
        }
        h3 {
            font-size: 24px !important;
            font-weight: 400 !important;
        }
        &-switch {
            padding-left: 4px;
            margin-top: 0;
            margin-bottom: 4px;
            .v-input--switch__track.primary--text {
                background-color: rgb(47,199,87) !important;
                opacity: 1;
            }
            .v-input--switch__thumb.theme--light.primary--text{
                background-color: rgb(255, 255, 255) !important;
            }
            .v-input--switch__thumb.theme--light .primary--text{
                background-color: rgb(255, 255, 255) !important;
            }
        }
        .add-btn {
            font-family: 'Roboto', sans-serif;
            background-color: #1736cb !important;
            font-size: 15px;
            opacity: 1 ;
            i{
                font-size: 13px !important;
            }
            font-weight: 400 !important;
            border-radius: 8px;
            span {
                color: #FFFFFF!important;
                letter-spacing:normal;
            }
        }
        .delete-btn {
            background-color: transparent !important;
            box-shadow: none;
            padding: 0 !important;
            min-width: 0 !important;
            margin: 0 !important;
            .v-btn__content i {
                color: red!important;
            }
        }
        &-file {
            padding: 0;
            margin: 0;
            .v-input__prepend-outer {
                position: absolute;
            }
            .v-input__slot {
                padding-left: 30px !important;
                min-height: 0px;
            }
            .v-text-field__slot {
                margin-top: 23px;
                height: 20px;
            }
            .v-text-field--outlined .v-label--active {
                -webkit-transform: translateY(-24px) scale(0.75); 
                transform: translateY(-24px) scale(0.75); 
            }
            .lb {
                position: absolute;
                left: 43px;
                font-size: 11px;
                line-height: 3.5;
                color: rgb(0, 0, 0);
            }
        }
    }
</style>