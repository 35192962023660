<template>
  <v-text-field
    :model-value="modelValue"
    @update:model-value="returnValue($event)"
    :label="label"
    color="#0078FF"
    variant="outlined"
    density="compact"
    :disabled="disabled"
    clearable
    style="border-radius: 6px"
    prepend-inner-icon="mdi-magnify"
    type="text"
    ref="input"
    hide-details
  ></v-text-field>
</template>
<script>
export default {
  props: {
    label: { type: String, default: "" },
    modelValue: {},
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({}),
  methods: {
    returnValue(item) {
      this.$emit("update:modelValue", item ? item.trim() : "");
    },
  },
  computed: {},
  mounted() {},
  created() {},
  watch: {},
};
</script>
<style scoped lang="scss">
</style>
