import { createRouter, createWebHistory } from 'vue-router'

import ViewDocuments from "../views/ViewDocuments.vue";
import SettingMobile from "../views/SettingMobile.vue";
import ViewPush from "../views/ViewPush.vue";
import PushDetail from "../views/PushDetail.vue";
import MailTemplates from "../views/MailTemplates.vue";
import ViewVersions from "../views/Version/ViewVersions.vue";
import AddVersions from "../views/Version/VersionAdd";
import VersionDetailed from "../views/Version/VersionDetailed";
import VersionUpdate from "../views/Version/VersionUpdate";
import CBanners from "../views/Banners/CBanners.vue";
import ModerationUsers from "../views/ModerationUsers.vue";

import Discount from "../views/ViewDiscount";
import Promocode from "../views/ViewPromocode";

import store from "../store/index";

const routes = [
  {
    path: "/",
    name: "ViewLayout",
    component: () => import("../views/ViewLayout.vue"),
    async beforeEnter(to, from, next) {
      if (await store.dispatch("authorizationAdminPanel")) {
        next();
      } else {
        next({ name: "ViewLogin" });
      }
    },
    children: [
      {
        path: "users",
        name: "ViewUsers",
        component: () => import("../views/Users/ViewUsers.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "user/:id",
        name: "ViewUser",
        component: () => import("../views/Users/ViewUser.vue"),
        meta: { parent: ["ViewUsers"] },
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "tags",
        name: "ViewTags",
        component: () => import("../views/Tags/ViewTags.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "groups",
        name: "ViewGroups",
        component: () => import("../views/Groups/ViewGroups.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "group/:id",
        name: "ViewGroup",
        meta: { parent: ["ViewGroups"] },
        component: () => import("../views/Groups/ViewGroup.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "tests",
        name: "ViewTests",
        component: () => import("../views/Tests/ViewTests.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "test/:id",
        name: "ViewTest",
        meta: { parent: ["ViewTests"] },
        component: () => import("../views/Tests/ViewTest.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "courses",
        name: "ViewCourses",
        component: () => import("../views/Courses/ViewCourses.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "course/:id",
        name: "ViewCourse",
        meta: { parent: ["ViewCourses"] },
        component: () => import("../views/Courses/ViewCourse.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "topics",
        name: "ViewTopics",
        component: () => import("../views/Topics/ViewTopics.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "newsArticles",
        name: "NewsArticles",
        component: () => import("../views/News/NewsArticles.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "news/:id",
        name: "ViewNews",
        meta: { parent: ["NewsArticles"] },
        component: () => import("../views/News/ViewNews.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "lesson/:id",
        name: "ViewLesson",
        meta: { parent: ["ViewCourses"] },
        component: () => import("../views/Lessons/ViewLesson.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },

      {
        path: "documents",
        name: "documents",
        component: ViewDocuments,
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "mailTemplates",
        name: "mailTemplates",
        component: MailTemplates,
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "settingMobile",
        name: "settingMobile",
        component: SettingMobile,
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },

      {
        path: "discount/:id",
        name: "discount",
        component: Discount,
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "promocode/:id",
        name: "promocode",
        component: Promocode,
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "push",
        name: "push",
        component: ViewPush,
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "push/:id",
        name: "pushDetail",
        component: PushDetail,
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "versions",
        name: "versions",
        component: ViewVersions,
        beforeEnter: async (to, from, next) => {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "version/add",
        name: "historlayoutadd",
        component: AddVersions,
        beforeEnter: async (to, from, next) => {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "version/details/:id",
        name: "versionDetails",
        component: VersionDetailed,
        beforeEnter: async (to, from, next) => {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "version/update/:id",
        name: "versionUpdate",
        component: VersionUpdate,
        beforeEnter: async (to, from, next) => {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "banners",
        name: "banners",
        component: CBanners,
        beforeEnter: async (to, from, next) => {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "moderation",
        name: "moderation",
        component: ModerationUsers,
        beforeEnter: async (to, from, next) => {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
    ],
  },
  // {
  //   path: "/mailConfirmation/:id",
  //   name: "mailConfirmation",
  //   component: MailConfirmation,
  // },
  {
    path: "/login",
    name: "ViewLogin",
    component: () => import("../views/ViewLogin.vue"),
    beforeEnter: async (to, from, next) => {
      if (await store.dispatch("authorizationAdminPanel")) {
        if (localStorage.getItem("lastPage") !== null) {
          const lastPage = window.localStorage
            .getItem("lastPage")
            .replace("/", "")
            .replace(/"/g, "");
          next({
            path: lastPage,
          });
        } else {
          next({ name: "ViewLayout" })
        }
      } else {
        next();
      }
    },
  },
  {
    path: "/:pathMatch(.*)*",
    beforeEnter: async (to, from, next) => {
      if (await store.dispatch("authorizationAdminPanel")) {
        next();
      } else {
        next({ name: "ViewLogin" });
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
