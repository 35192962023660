<template>
    <div>
        <v-snackbar
                :location="true ? 'top' : undefined"
                v-model="snackbar.isOpen"
        >
            {{ snackbar.text }}
            <v-btn
                    @click="snackbar.isOpen = false"
                    color="red"
                    variant="text"
            >
                Close
            </v-btn>
        </v-snackbar>
        <v-card class="elevation-0">
            <v-card-title>
                ViewPush-уведомления
                <v-spacer></v-spacer>
                <v-text-field
                        @update:model-value="getPushList"
                        append-icon="mdi-magnify"
                        class="mr-10"
                        hide-details
                        label="Поиск"
                        single-line
                        v-model="search"
                ></v-text-field>

                <v-dialog max-width="500px" persistent v-model="dialog">
                    <template v-slot:activator="{ props }">
                        <v-btn variant="flat" class="button-action" v-bind="props">Создать</v-btn>
                    </template>
                    <v-card >
                        <v-card-title class="dialog-title">
                            <span>Новое ViewPush-уведомление</span>
                            <v-icon @click="cancelPushBtn">mdi-close</v-icon>
                        </v-card-title>
                        <v-form ref="push" validate-on="lazy">
                        <v-card-text class="dialog-description">
                               <div class="dialog-object">
                                        <v-text-field variant="outlined" label="Заголовок" v-model="push.title"></v-text-field>
                               </div>
                                <div class="dialog-object">
                                        <v-textarea
                                                variant="outlined"
                                                label="Текст"
                                                no-resize
                                                rows="3"
                                                v-model="push.description"></v-textarea>
                                </div>
                                <div class="dialog-object">
                                        <v-file-input accept="image/*"
                                                variant="outlined"
                                                :rules="[ value => (value && value.size < 2148000000) || 'Размер изображения не больше 2 Гб' ]"
                                                hint="Максимальный размер изображения 2 Гб"
                                                persistent-hint
                                                label="Фото" v-model="push.photo"></v-file-input>
                                </div>
                                   <div class="dialog-object">
                                                <v-menu
                                                        outlined
                                                        :close-on-content-click="false"
                                                        max-width="290px"
                                                        min-width="290px"
                                                        location="bottom"
                                                        transition="scale-transition"
                                                        v-model="pushStartPublicationMenu"
                                                        :offset="315"
                                                >
                                                    <template v-slot:activator="{ props }">
                                                        <v-text-field
                                                                variant="outlined"
                                                                @blur="pushStartPublicationDate = parseDate(pushStartPublicationDateFormatted)"
                                                                @click:clear="pushStartPublicationDate = null"
                                                                clearable
                                                                label="Начало публикации"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-model="pushStartPublicationDateFormatted"
                                                                v-bind="props"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-card>
                                                    <v-tabs v-model="pushStartPublicationTab" grow>
                                                        <v-tab href="#tab-1"><v-icon>mdi-calendar</v-icon></v-tab>
                                                        <v-tab href="#tab-2"><v-icon>mdi-clock-outline</v-icon></v-tab>

                                                        <v-window-item
                                                                value="tab-1"
                                                        >    
                                                            <v-date-picker @input="pushStartPublicationTab = 'tab-2'" no-title
                                                                   v-model="pushStartPublicationDate"></v-date-picker>
                                                        </v-window-item>
                                                        <v-window-item
                                                                value="tab-2"
                                                        >    
                                                            <v-time-picker
                                                                    outlined
                                                                    @input="pushStartPublicationMenu = false; pushStartPublicationTab = 'tab-1';"
                                                                    v-model="pushStartPublicationTime" 
                                                                    format='24h'
                                                            ></v-time-picker>
                                                        </v-window-item>
                                                    </v-tabs>
                                                    </v-card>
                                                </v-menu>
                                            </div>

                                            <div class="dialog-object">
                                                <v-menu
                                                        :close-on-content-click="false"
                                                        max-width="290px"
                                                        min-width="290px"
                                                        location="bottom"
                                                        transition="scale-transition"
                                                        v-model="pushEndPublicationMenu"
                                                        :offset="315"
                                                >
                                                    <template v-slot:activator="{ props }">
                                                        <v-text-field
                                                                variant="outlined"
                                                                @click:clear="pushEndPublicationDate = parseDate(pushEndPublicationDateFormatted)"
                                                                clearable
                                                                label="Конец публикации"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-model="pushEndPublicationDateFormatted"
                                                                v-bind="props"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-tabs v-model="pushEndPublicationTab" grow>
                                                        <v-tab href="#tab-1"><v-icon>mdi-calendar</v-icon></v-tab>
                                                        <v-tab href="#tab-2"><v-icon>mdi-clock-outline</v-icon></v-tab>

                                                        <v-window-item
                                                                value="tab-1"
                                                        >    
                                                            <v-date-picker @input="pushEndPublicationTab = 'tab-2'" no-title
                                                                   v-model="pushEndPublicationDate"></v-date-picker>
                                                        </v-window-item>
                                                        <v-window-item
                                                                value="tab-2"
                                                        >    
                                                            <v-time-picker
                                                                    @input="pushEndPublicationMenu = false; pushEndPublicationTab = 'tab-1';"
                                                                    v-model="pushEndPublicationTime" 
                                                                    format='24h'
                                                            ></v-time-picker>
                                                        </v-window-item>
                                                    </v-tabs>
                                                </v-menu>
                                          </div>
                                       
                                    <div style="padding-bottom:20px">
                                                <span style="font-size: 16px">Период регистрации пользователя</span>
                                    </div>
                                    <div class="dialog-object">
                                                <v-menu
                                                        :close-on-content-click="false"
                                                        max-width="290px"
                                                        min-width="290px"
                                                        location="bottom"
                                                        transition="scale-transition"
                                                        v-model="pushRegUserFromDateMenu"
                                                >
                                                    <template v-slot:activator="{ props }">
                                                        <v-text-field
                                                                variant="outlined"
                                                                @blur="pushRegUserFromDate = parseDate(pushRegUserFromDateFormatted)"
                                                                @click:clear="pushRegUserFromDate = null"
                                                                clearable
                                                                label="Выбор даты «от»"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-model="pushRegUserFromDateFormatted"
                                                                v-bind="props"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker @input="pushRegUserFromDateMenu = false" no-title
                                                                   v-model="pushRegUserFromDate"></v-date-picker>
                                                </v-menu>
                                    </div>
                                    <div class="dialog-object">
                                     
                                                <v-menu
                                                        :close-on-content-click="false"
                                                        max-width="290px"
                                                        min-width="290px"
                                                        location="bottom"
                                                        transition="scale-transition"
                                                        v-model="pushRegUserToMenu"
                                                >
                                                    <template v-slot:activator="{ props }">
                                                        <v-text-field
                                                                variant="outlined"
                                                                @click:clear="pushRegUserToDate = parseDate(pushRegUserToFormatted)"
                                                                clearable
                                                                label="Выбор даты «по»"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-model="pushRegUserToFormatted"
                                                                v-bind="props"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker @input="pushRegUserToMenu = false" no-title
                                                                   v-model="pushRegUserToDate"></v-date-picker>
                                                </v-menu>
                                       </div>
                                    <div class="dialog-object">
                                        <v-select
                                                variant="outlined"
                                                :items="typeDevices"
                                                item-title="name"
                                                item-value="id"
                                                label="Тип устройства"
                                                v-model="push.typeDevice"
                                        ></v-select>
                                    </div>
                                   <div class="dialog-object">
                                        <v-select
                                                variant="outlined"
                                                :items="availabilityPurchases"
                                                item-title="name"
                                                item-value="id"
                                                label="Наличие покупок"
                                                v-model="push.availabilityPurchase"
                                        ></v-select>
                                   </div>
                                <div class="dialog-object">
                                    <v-progress-linear
                                        :active="activeProgress"
                                        color="blue"
                                        style="max-height: 2px"
                                        v-model="progress"
                                    ></v-progress-linear>
                                    </div>
                               
                        </v-card-text>
                        <v-card-actions class="dialog-buttons">
                            <v-btn @click="cancelPushBtn" variant="flat" class="button-dialog-cancel">Отмена
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="savePushBtn" variant="flat" class="button-dialog-accept">Добавить
                            </v-btn>
                        </v-card-actions>
                        </v-form>
                    </v-card>
                </v-dialog>
            </v-card-title>
            <v-data-table-server
                    :footer-props="{ itemsPerPageOptions:[10,25,50] }"
                    :headers="authorsHeaders"
                    :items="pushList"
                    :items-per-page="optionTable.itemsPerPage"
                    :page="optionTable.page"
                    :search="search"
                    :items-length="optionTable.serverItemsLength"
                    @click:row="onClickRow"
                    @pagination="tableChange"
                    class="elevation-0"
            >
                <template v-slot:no-data>
                    <v-btn @click="getPushList" variant="flat" class="button-action" >Обновить</v-btn>
                </template>

                <template v-slot:item.dateCreated="{ item }">
                    {{ $filters.moment(item.dateCreated, "HH:mm DD.MM.YYYY")}}
                </template>
                <template v-slot:item.intervalPublication="{ item }">
                    {{ $filters.moment(item.startPublicationDate, "DD.MM.YYYY") }} - {{ $filters.moment(item.endPublicationDate, "DD.MM.YYYY") }}
                </template>
                <template v-slot:item.status="{ item }">
                   <span v-if="item.status=='new'">Создано</span>
                   <span v-if="item.status=='post'">Опубликовано</span>
                   <span v-if="item.status=='stop'">Остановлено</span>
                </template>
            </v-data-table-server>
        </v-card>
        <div class="line gray"></div>
    </div>
</template>

<script>

    import axios from 'axios'

    export default {
        data: () => ({
            dialog: false,
            snackbar: {
                isOpen: true,
                text: ''
            },
            search: '',
            optionTable: {
                page: 1,
                itemsPerPage: 5,
                serverItemsLength: -1
            },
            authorsHeaders: [
                {
                    text: 'Заголовок',
                    align: 'left',
                    sortable: false,
                    value: 'title',
                },
                {
                    text: 'Текст',
                    align: 'left',
                    sortable: false,
                    value: 'description',
                },
                {
                    text: 'Статус',
                    align: 'center',
                    sortable: false,
                    value: 'status',
                },
                {
                    text: 'Дата создания',
                    align: 'right',
                    sortable: false,
                    value: 'dateCreated',
                },
                {
                    text: 'Интервал публикации',
                    align: 'right',
                    sortable: false,
                    value: 'intervalPublication',
                }
            ],
            availabilityPurchases: [
                {id: "", name: 'Не важно'},
                {id: 'yes', name: 'Есть'},
                {id: 'no', name: 'Нет'},
            ],
            typeDevices: [
                {id: 'ios', name: 'iOS'},
                {id: 'android', name: 'Android'},
            ],
            pushList: [],
            push: {},
            pushStartPublicationDate: null,
            pushEndPublicationDate: null,
            pushStartPublicationTime: '00:00',
            pushEndPublicationTime: '00:00',
            pushRegUserFromDate: null,
            pushRegUserToDate: null,
            pushStartPublicationMenu: false,
            pushEndPublicationMenu: false,
            pushRegUserFromDateMenu: false,
            pushRegUserToDateMenu: false,
            pushRegUserToMenu: false,

            pushStartPublicationTab: 'tab-1',
            pushEndPublicationTab: 'tab-1',
            activeProgress: false,
            progress: 0,

        }),

        created() {
            this.getPushList()
        },
        computed: {},

        watch: {
            pushStartPublicationDate() {
                this.pushStartPublicationDateFormatted = this.formatDate(this.pushStartPublicationDate) + ' ' + this.pushStartPublicationTime
            },
            pushStartPublicationTime() {
                this.pushStartPublicationDateFormatted = this.formatDate(this.pushStartPublicationDate) + ' ' + this.pushStartPublicationTime
            },
            pushEndPublicationDate() {
                this.pushEndPublicationDateFormatted = this.formatDate(this.pushEndPublicationDate) + ' ' + this.pushEndPublicationTime
            },
            pushEndPublicationTime() {
                this.pushEndPublicationDateFormatted = this.formatDate(this.pushEndPublicationDate) + ' ' + this.pushEndPublicationTime
            },
            pushRegUserFromDate() {
                this.pushRegUserFromDateFormatted = this.formatDate(this.pushRegUserFromDate)
            },
            pushRegUserToDate() {
                this.pushRegUserToFormatted = this.formatDate(this.pushRegUserToDate)
            },
        },

        methods: {
            formatDate(date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${day}.${month}.${year}`
            },
            parseDate(date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            getPushList() {
                this.snackbar.isOpen = false
                axios.post(this.$store.getters.apiAdminPanelV3 + '/push/get', {
                    optionTable: this.optionTable,
                    search: this.search
                }, {
                    headers: {
                        'authorization': this.$store.getters.adminPanelHeaderAuth
                    }
                })
                    .then(res => {
                        this.optionTable = res.data.optionTable;
                        this.pushList = res.data.items;
                    })
                    .catch(err => {
                        this.snackbar.isOpen = true
                        this.snackbar.text = err.response.data || err.stack
                    })
            },
            tableChange(data) {
                this.optionTable.page = data.page
                this.optionTable.itemsPerPage = data.itemsPerPage
                this.getPushList()
            },
            cancelPushBtn() {
                this.dialog = false
                this.push = {};
            },
            async savePushBtn() {
                if ((await this.$refs.push.validate()).valid) {
                let formData = new FormData();
                formData.append('title', this.push.title || "");
                formData.append('description', this.push.description || "");
                formData.append('photo', this.push.photo || "");
                formData.append('typeDevice', this.push.typeDevice || "");
                formData.append('availabilityPurchase', this.push.availabilityPurchase || "");
                if (this.pushRegUserFromDate) {
                    formData.append('regUserFromDate', (new Date(this.pushRegUserFromDate)).getTime());
                }
                if (this.pushRegUserToDate) {
                    formData.append('regUserToDate', (new Date(this.pushRegUserToDate)).getTime());
                }
                if (this.pushStartPublicationDate) {
                    formData.append('startPublicationDate', (new Date(this.pushStartPublicationDate + ' ' + this.pushStartPublicationTime)).getTime());
                }
                if (this.pushEndPublicationDate) {
                    formData.append('endPublicationDate', (new Date(this.pushEndPublicationDate + ' ' + this.pushEndPublicationTime)).getTime());
                }

                this.activeProgress = true
                axios.post(this.$store.getters.apiAdminPanelV3 + '/push', formData, {
                    headers: {
                        'authorization': this.$store.getters.adminPanelHeaderAuth
                    },
                      onUploadProgress: progressEvent => {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    
                        this.progress = percentCompleted
                    }
                  
                })
                    .then(() => {
                      this.activeProgress = false
                        this.getPushList()
                        this.push = {}
                        this.dialog = false
                    })
                    .catch(err => {
                        this.snackbar.isOpen = true
                        this.snackbar.text = err.response.data || err.stack
                    })
                }

            },
            onClickRow(row) {
                this.$router.push({name: 'pushDetail', params: {id: row.id}})
            }
        
        },
    }
</script>
