<template>
  <transition name="fade" v-show="modelValue">
    <div class="dialog-image">
      <div class="dialog-background" @click="[closeDialog(), $refs.video_player.pause()]"></div>
      <div class="dialog-container" @click="closeDialog">
        <video
          ref="video_player"
          class="video-player"
          controls
          :src="src"
          controlsList="noremoteplayback"
        ></video>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    modelValue: { type: Boolean, default: false },
    src: { type: String },
  },
  data: () => ({
    imageWidth: 0,
    imageHeight: 0,
  }),
  created() {},
  updated() {},
  methods: {
    closeDialog() {
      this.$emit("update:modelValue", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-image {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  .dialog-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: RGBA(112, 112, 112, 0.2);
    backdrop-filter: blur(10px);
    // backdrop-filter: blur(10px);
  }
  .dialog-container {
    z-index: 10;

    .video-player {
      max-width: 1000px;
      max-height: 700px;
      //   object-fit: contain;
    }
  }
}

.fade-enter-active {
  .dialog-background {
    opacity: 0;
    transition: all 0.3s ease;
  }
  .dialog-container {
    transition: all 0.3s ease;
    transform: translateY(50px);
    opacity: 0;
  }
}
.fade-enter-to {
  transition: all 0.3s ease;
  .dialog-background {
    opacity: 1;
  }

  .dialog-container {
    transition: all 0.3s ease;
    opacity: 1;
    transform: translateY(0px);
  }
}
.fade-leave-active {
  transition: all 0.3s ease;
  .dialog-background {
    opacity: 1;
  }

  .dialog-container {
    opacity: 1;
    transform: translateY(0px);
  }
}
.fade-leave-to {
  .dialog-background {
    opacity: 0;
    transition: all 0.3s ease;
  }

  .dialog-container {
    transition: all 0.3s ease;
    opacity: 0;
    transform: translateY(20px);
  }
}
</style>