<template>
  <div class="author">
    <v-snackbar :location="true ? 'top' : undefined" v-model="snackbar.isOpen">
      {{ snackbar.text }}
      <v-btn @click="snackbar.isOpen = false" color="red" variant="text"> Close </v-btn>
    </v-snackbar>
    <v-snackbar
      color="error"
      location="top"
      :timeout="0"
      v-model="directoriesSnackbar.isOpen"
    >
      Для добавления курса необходимо задать {{ directoriesSnackbar.text }} в
      разделе Справочники.
      <v-btn @click="directoriesSnackbar.isOpen = false" theme="dark" variant="flat">
        Close
      </v-btn>
    </v-snackbar>
    <v-card-title>
      <v-btn :to="{ name: 'stocks' }" variant="text"> Назад</v-btn>
      <v-spacer></v-spacer>
      <v-dialog max-width="500px" persistent v-model="dialogDiscount">
        <template v-slot:activator="{ props }">
          <v-btn
            @click="openFormDiscount"
            class="ml-6 button-action-none-color"
            theme="dark"
            style="width: 200px !important"
            v-bind="props"
            >Редактировать скидку
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h6 ml-3">Данные ментора</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-form ref="discountForm" validate-on="lazy">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="11"
                    style="height: 65px; margin-left: 35px; padding-right: 8px"
                  >
                    <v-text-field
                      :rules="rules"
                      label="Название"
                      v-model="discountCopy.name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="11"
                    style="height: 65px; margin-left: 35px; padding-right: 8px"
                  >
                    <v-text-field
                      type="number"
                      :rules="rules"
                      label="Процент"
                      v-model="discountCopy.percent"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="11"
                    style="height: 65px; margin-left: 35px; padding-right: 8px"
                  >
                    <v-select
                      :items="rates"
                      :rules="[(value) => !!value || 'Выберите тариф']"
                      item-title="text"
                      item-value="value"
                      label="Тариф"
                      v-model="discountCopy.rate"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
          <v-card-actions class="pb-10">
            <v-btn @click="cancelDiscountBtn" class="ml-6 text-caption button-cancel"
              >Отмена</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn @click="saveDiscountBtn" class="mr-6 text-caption button-green"
              >Сохранить</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-btn
        @click="blockDiscountBtn(false)"
        class="ml-6 button-cancel"
        style="width: 200px !important"
        v-if="discount.isUse"
        >Отключить скидку
      </v-btn>
      <v-btn
        @click="blockDiscountBtn(true)"
        class="ml-6 button-action"
        style="width: 200px !important"
        v-if="!discount.isUse"
        >Включить скидку
      </v-btn>
    </v-card-title>

    <table class="mt-12 ml-6 mb-12" style="width: 100%">
      <v-row>
        <v-col class="table-first-cell">ID:</v-col>
        <v-col class="pl-5">{{ discount.id }}</v-col>
      </v-row>
      <v-row>
        <v-col class="table-first-cell">Название:</v-col>
        <v-col class="pl-5">{{ discount.name }}</v-col>
      </v-row>
      <v-row>
        <v-col class="table-first-cell">Процент:</v-col>
        <v-col class="pl-5">{{ discount.percent }}</v-col>
      </v-row>
      <v-row>
        <v-col class="table-first-cell">Тариф:</v-col>
        <v-col class="pl-5">{{
          discount.rate == "not_limited" ? "Цена навсегда" : "Цена на месяц"
        }}</v-col>
      </v-row>
      <v-row>
        <v-col class="table-first-cell">Дата создания:</v-col>
        <v-col class="pl-5">{{
          $filters.moment(discount.dateCreated, "HH:mm DD.MM.YYYY")
        }}</v-col>
      </v-row>
    </table>

    <v-card>
      <v-card-title>
        Курсы
        <v-spacer></v-spacer>

        <v-dialog max-width="500px" persistent v-model="dialogCourse">
          <template v-slot:activator="{ props }">
            <v-btn
              class="button-action"
              color="primary"
              theme="dark"
              v-bind="props"
              @click="directoriesSnackbar.isOpen = directoriesSnackbar.toOpen"
              >Добавить курс
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h6 ml-3">Добавление курса</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-form validate-on="lazy" ref="course">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="11"
                      style="
                        height: 65px;
                        margin-left: 35px;
                        padding-right: 8px;
                      "
                    >
                      <v-select
                        :items="authors"
                        item-title="fullName"
                        item-value="authorId"
                        @update:model-value="getCourses"
                        v-model="authorId"
                        :rules="rules"
                        label="Авторы"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="11"
                      style="
                        height: 65px;
                        margin-left: 35px;
                        padding-right: 8px;
                      "
                    >
                      <v-select
                        :items="courses"
                        item-title="name"
                        item-value="courseId"
                        v-model="courseId"
                        :rules="rules"
                        label="Курсы"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="pb-10">
                <v-btn @click="cancelCourseBtn" class="ml-6 button-cancel"
                  >Отмена</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn @click="saveCourseBtn" class="mr-6 button-green"
                  >Добавить</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table-server
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25],
        }"
        :headers="courseHeaders"
        :items="discountCourses"
        :items-per-page="courseOptionTable.itemsPerPage"
        :page="courseOptionTable.page"
        :items-length="courseOptionTable.serverItemsLength"
        @pagination="courseTableChange"
        class="elevation-1"
      >
        <template v-slot:no-data>
          <v-btn @click="getDiscountCourses" color="primary">Обновить</v-btn>
        </template>
        <template v-slot:item.dateCreated="{ item }">
          {{ $filters.moment(item.dateCreated, "DD.MM.YYYY") }}
        </template>
        <template v-slot:item.course="{ item }">
          {{ item.course.name }}
        </template>
        <template v-slot:item.author="{ item }">
          {{ item.course.author.fullName }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon @click="onClickRow(item)" class="mr-2" size="small">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table-server>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "view-discount",
  data: () => ({
    rates: [
      { text: "Цена навсегда", value: "not_limited" },
      { text: "Цена на месяц", value: "month" },
    ],

    dialogDiscount: false,
    discount: {},
    discountCopy: {},

    dialogCourse: false,
    dialogSocial: false,
    snackbar: {
      isOpen: false,
      text: "",
    },
    directoriesSnackbar: {
      toOpen: false,
      isOpen: false,
      text: "",
    },
    rules: [(value) => !!value || value == 0 || "Обязательно к заполнению"],

    authors: [],
    authorId: null,
    courseId: null,
    courseHeaders: [
      {
        text: "Название",
        align: "left",
        sortable: false,
        value: "course",
      },
      {
        text: "Автор",
        align: "left",
        sortable: false,
        value: "author",
      },
      {
        text: "Дата",
        align: "left",
        sortable: false,
        value: "dateCreated",
      },
      { text: "   ", align: "right", value: "actions", sortable: false },
    ],
    courses: [],
    discountCourses: [],
    courseOptionTable: {
      page: 1,
      itemsPerPage: 5,
      serverItemsLength: -1,
    },
  }),
  created() {
    this.getDiscount();
    this.getAuthors();
    this.getDiscountCourses();
  },
  methods: {
    getAuthors() {
      axios
        .post(
          this.$store.getters.apiAdminPanelV1 + "/author/list",
          this.discountCopy,
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.authors = res.data;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },

    cancelDiscountBtn() {
      this.dialogDiscount = false;
    },
    async saveDiscountBtn() {
      if ((await this.$refs.discountForm.validate()).valid) {
        axios
          .post(
            this.$store.getters.apiAdminPanelV1 + "/discount",
            this.discountCopy,
            {
              headers: {
                authorization: this.$store.getters.adminPanelHeaderAuth,
              },
            }
          )
          .then(() => {
            this.getDiscount();
            this.discount = {};
            this.dialogDiscount = false;
          })
          .catch((err) => {
            this.snackbar.isOpen = true;
            this.snackbar.text = err.response.data || err.stack;
          });
      }
    },
    openFormDiscount() {
      this.discountCopy = Object.assign({}, this.discount);
    },
    blockDiscountBtn(isUse) {
      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV1 + "/discount/use",
          {
            id: this.$route.params.id,
            isUse: isUse,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          this.getDiscount();
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    getDiscount() {
      this.snackbar.isOpen = false;
      axios
        .get(this.$store.getters.apiAdminPanelV1 + "/discount", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.discount = res.data;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },

    cancelCourseBtn() {
      this.dialogCourse = false;
      this.directoriesSnackbar.isOpen = false;
      this.courseId = null;
      this.authorId = null;
    },
    async saveCourseBtn() {
      if ((await this.$refs.course.validate()).valid) {
        axios
          .post(
            this.$store.getters.apiAdminPanelV1 + "/discount/course",
            {
              discountId: this.$route.params.id,
              courseId: this.courseId,
            },
            {
              headers: {
                authorization: this.$store.getters.adminPanelHeaderAuth,
              },
            }
          )
          .then(() => {
            this.dialogCourse = false;
            this.courseId = null;
            this.authorId = null;
            this.getCourses();
          })
          .catch((err) => {
            this.snackbar.isOpen = true;
            this.snackbar.text = err.response.data || err.stack;
          });
      }
    },
    getCourses() {
      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV1 + "/course/list",
          {
            authorId: this.authorId,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.courses = res.data;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },

    getDiscountCourses() {
      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV1 + "/discount/courses/get",
          {
            optionTable: this.courseOptionTable,
            discountId: this.$route.params.id,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.courseOptionTable = res.data.optionTable;
          this.discountCourses = res.data.items;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },

    courseTableChange(data) {
      this.courseOptionTable.page = data.page;
      this.courseOptionTable.itemsPerPage = data.itemsPerPage;
      this.getDiscountCourses();
    },

    onClickRow(item) {
      if (!confirm("Удалить?")) {
        return;
      }

      axios
        .delete(this.$store.getters.apiAdminPanelV1 + "/discount/course", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: item.id,
          },
        })
        .then(() => {
          this.getDiscountCourses();
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
  },
};
</script>
