<template>
  <v-file-input
    truncate-length="50"
    :model-value="typeof modelValue == 'object' ? modelValue : newValue"
    @update:modelValue="setFile($event)"
    :label="label"
    variant="outlined"
    :rules="checkRules"
    color="#0078FF"
    :accept="acceptFiles"
    :hide-details="hideDetails"
    :prepend-icon="null"
  ></v-file-input>
</template>
<script>
export default {
  props: {
    label: { type: String, default: "" },
    valid: { type: Boolean, default: false },
    acceptFiles: { type: String, default: "*" },
    hideDetails: { type: Boolean, default: false },
    modelValue: {},
  },
  data: () => ({
    newValue: null,
  }),
  methods: {
    setFile(e){
      this.$emit('update:modelValue', e)
      this.$emit('change', e)
    }
  },
  created() {
    if (this.modelValue) {
      if (typeof this.modelValue == "string") {
        this.src = this.modelValue;
        const obj = new Object();
        obj.src = this.src;
        obj.name = "Ранее загруженный файл";
        this.newValue = obj;
      } else {
        this.src = URL.createObjectURL(this.modelValue);
      }
    }
  },
  computed: {
    checkRules() {
      if (this.valid) {
        return [(value) => !!value || "Обязательно к заполнению"];
      }
      return [];
    },
  },
  mounted() {},

  watch: {
    modelValue() {
      if (this.modelValue) {
        if (typeof this.modelValue == "string") {
          this.src = this.modelValue;
          const obj = new Object();
          obj.src = this.src;
          obj.name = "Ранее загруженный файл";
          this.newValue = obj;
        } else {
          this.src = URL.createObjectURL(this.modelValue);
        }
      }
      else {
        this.newValue = null;
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
