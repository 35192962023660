<template>
  <transition name="fade">
    <div class="dialog-container" @keyup.esc="closeDialog" v-if="modelValue">
      <div
        class="dialog-container-block"
        :style="`width:${width}px !important`"
      >
        <div class="dialog-block-title">
          <slot name="blockTitle">
            <div class="block-title-text">
              <slot name="blockTitleText">
                {{ titleText }}
              </slot>
            </div>
            <div class="block-title-icon">
              <v-icon @click="closeDialog">mdi-close</v-icon>
            </div>
          </slot>
        </div>
        <v-form validate-on="lazy" :ref="refForm" class="dialog-block-main">
          <slot name="blockMain">
            <div v-for="(i, index) in mainItems" :key="index">
              <slot v-if="i.type == 'text'" :name="i.slotName">
                <TextInput
                  :maxLength="i.maxLength"
                  :label="i.label"
                  v-model="i.value"
                  :valid="i.valid"
                  @update:modelValue="i.vmodel"
                />
              </slot>
              <slot v-else-if="i.type == 'image'" :name="i.slotName">
                <ImageInput
                  :label="i.label"
                  v-model="i.value"
                  :valid="i.valid"
                  @update:modelValue="i.vmodel"
                />
              </slot>
              <slot v-else-if="i.type == 'textarea'" :name="i.slotName">
                <TextArea
                  :maxLength="i.maxLength"
                  :label="i.label"
                  v-model="i.value"
                  :valid="i.valid"
                  @update:modelValue="i.vmodel"
                />
              </slot>
              <slot v-else-if="i.type == 'select'" :name="i.slotName">
                <SelectInput
                  :items="i.items"
                  :label="i.label"
                  v-model="i.value"
                  :valid="i.valid"
                  :itemTitle="i.itemText"
                  :itemValue="i.itemValue"
                  @update:modelValue="i.vmodel"
                />
              </slot>
              <slot v-else-if="i.type == 'file'" :name="i.slotName">
                <FileInput
                  :label="i.label"
                  v-model="i.value"
                  :valid="i.valid"
                  @update:modelValue="i.vmodel"
                />
              </slot>
              <slot v-else-if="i.type == 'video'" :name="i.slotName">
                <VideoInput
                  :label="i.label"
                  v-model="i.value"
                  :valid="i.valid"
                  @update:modelValue="i.vmodel"
                />
              </slot>
              <slot v-else-if="i.type == 'checkbox'" :name="i.slotName">
                <CheckBoxInput
                  :label="i.label"
                  v-model="i.value"
                  :valid="i.valid"
                  @update:modelValue="i.vmodel"
                />
              </slot>
              <slot v-else-if="i.type == 'switch'" :name="i.slotName">
                <SwitchInput
                  :label="i.label"
                  :icon="i.icon"
                  v-model="i.value"
                  :valid="i.valid"
                  @update:modelValue="i.vmodel"
                />
              </slot>
              <slot v-else :name="i.slotName"> </slot>
            </div>
          </slot>
        </v-form>
        <div
          class="dialog-block-footer"
          :class="
            !buttonsFooter.delete || !buttonsFooter.delete.title
              ? 'justify-end'
              : 'justify-space-between'
          "
        >
          <slot name="blockFooter">
            <slot name="buttonDelete">
              <v-btn
                v-if="buttonsFooter.delete && buttonsFooter.delete.title"
                variant="flat"
                class="button-white-red"
                @click="deleteItem"
                :disabled="buttonsFooter.success.loading ? loading : null"
              >
                {{ buttonsFooter.delete.title }}
              </v-btn>
            </slot>
            <slot name="buttonSucces">
              <v-btn
                variant="flat"
                class="button-blue"
                @click="sendForm"
                :loading="buttonsFooter.success.loading ? loading : null"
              >
                {{ buttonsFooter.success.title }}
              </v-btn>
            </slot>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import VideoInput from "@/components/main/inputs/VideoInput";
import SwitchInput from "@/components/main/inputs/SwitchInput";

export default {
  components: {
    VideoInput,
    SwitchInput,
  },
  props: {
    modelValue: { type: Boolean, default: false },
    titleText: { type: String, default: "" },
    width: { type: Number, default: null },
    refForm: { type: String, default: "" },
    mainItems: {
      type: Array,
      default() {
        return [];
      },
    },
    child: { type: Object },
    buttonsFooter: {
      type: Object,
      default() {
        return {
          success: {
            title: "Добавить",
            loading: true
          },
          delete: {
            title: "Удалить",
            loading: true
          },
        };
      },
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    ckickItem(item) {
      this.$emit("ckickItem", item);
    },
    closeDialog() {
      if (this.refForm) {
        this.$refs[this.refForm].reset();
        this.$refs[this.refForm].resetValidation();
      }
      this.$emit("closeDialog");
      this.$emit("update:modelValue", false);
    },
    deleteItem() {
      this.$emit("click:delete");
    },
    async sendForm() {
      if (this.refForm) {
        if ((await this.$refs[this.refForm].validate()).valid) {
          this.loading = true;
          this.$emit("click:success", this.closeDialog);
        }
      } else {
        this.loading = true;
        this.$emit("click:success", this.closeDialog);
      }
    },
    clickEsc(e) {
      if (e.keyCode === 27) {
        if (this.modelValue) {
          this.closeDialog();
        }
      }
    },
  },
  computed: {},
  created() {},
  watch: {
    modelValue() {
      this.loading = false;
    },
    "$store.state.error"() {
      if (this.$store.state.error) {
        this.loading = false;
      }
    },
  },
  mounted() {
    document.addEventListener("keyup", this.clickEsc);
  },
  destroyed() {
    document.removeEventListener("keyup", this.clickEsc);
  },
};
</script>
<style scoped lang="scss">
.dialog-container {
  width: 100vw;
  height: 100vh;
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  background: RGBA(112, 112, 112, 0.2);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  .dialog-container-block {
    width: 600px;
    max-height: calc(100vh - 100px);
    border-radius: 10px;
    margin: 20px;
    position: absolute;
    z-index: 12;
    background: #f7f7fa;
    overflow: auto;

    .dialog-block-title {
      padding: 32px 40px 20px 40px;
      border-bottom: 1px solid #ddddea;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .block-title-text {
        font-size: 22px;
        font-weight: 600;
        margin-right: 20px;
      }
    }
    .dialog-block-main {
      display: flex;
      flex-direction: column;
      height: auto;
      max-height: calc(100vh - 300px);
      overflow-y: auto;
      padding: 32px 40px 16px 40px;
    }
    .dialog-block-footer {
      padding: 16px 40px 32px 40px;
      display: flex;
      align-items: center;
    }
  }
}

.fade-enter-active {
  opacity: 0;

  .dialog-container-block {
    transition: all 0.3s ease;
    transform: translateY(50px);
  }
}
.fade-enter-to {
  transition: all 0.3s ease;
  opacity: 1;
  .dialog-container-block {
    transition: all 0.3s ease;
    transform: translateY(0);
  }
}
.fade-leave-active {
  transition: all 0.3s ease;
  opacity: 0;
  .dialog-container-block {
    transition: all 0.3s ease;
    transform: translateY(50px);
  }
}
</style>
