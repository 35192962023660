import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";
import VueClipboard from "vue-clipboard2";
import axios from "axios";
import Mixins from "@/mixins/mixin";
import CTable from "@/components/main/table/CTable";
import DialogImage from "@/components/main/dialog/DialogImage";
import CDialog from "@/components/main/dialog/CDialog";
import TextInput from "@/components/main/inputs/TextInput";
import ImageInput from "@/components/main/inputs/ImageInput";
import TextArea from "@/components/main/inputs/TextArea";
import SelectInput from "@/components/main/inputs/SelectInput";
import FileInput from "@/components/main/inputs/FileInput";
import CheckBoxInput from "@/components/main/inputs/CheckBoxInput";
import SwitchInput from "@/components/main/inputs/SwitchInput";
import Trumbowyg from "vue-trumbowyg";
import moment from 'moment';

moment.locale('ru');
import "trumbowyg/dist/ui/trumbowyg.css";
import "trumbowyg/dist/langs/ru.js";

import {createApp} from "vue"
const app = createApp(App);

app.mixin(Mixins);

app.use(router);
app.use(store);
app.use(vuetify);
app.use(Trumbowyg);
app.use(VueClipboard);

app.component("CTable", CTable);
app.component("CDialog", CDialog);
app.component("DialogImage", DialogImage);
app.component("TextInput", TextInput);
app.component("ImageInput", ImageInput);
app.component("TextArea", TextArea);
app.component("SelectInput", SelectInput);
app.component("FileInput", FileInput);
app.component("CheckBoxInput", CheckBoxInput);
app.component("SwitchInput", SwitchInput);

app.config.globalProperties.$cookies = VueCookies;
const axiosInstance = axios.create()
app.config.globalProperties.$axios = { ...axiosInstance }
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$filters = {
  moment(value, format) {
    if (value === null || value === undefined || format === undefined) {
      return "";
    }
    if (format === "from") {
      return moment(value).fromNow();
    }
    return moment(value).format(format);
  }
}

app.mount("#app");

