<template>
    <div class="version__add" > 
        <v-card-title class="d-flex justify-space-between align-center">
            <div>
                <v-btn
                    variant="flat"
                    @click="rout()"
                    class="mr-4"
                ><v-icon>mdi-chevron-left</v-icon></v-btn>
                Добавление версии
            </div>
            <v-btn
                class="button-dialog-accept"
                @click="createVersions"
                style="width: 200px !important"   
                variant="flat"  
            >
                Создать версию
            </v-btn>
        </v-card-title>
        <div class="d-flex justify-space-between version__add-boby">
            <div class="version__add-left">
                <v-container>
                    <div>
                        <h3>Обновления в версии</h3>
                    </div>
                        <v-form ref="validInfo" v-model="validInfo" validation>
                            <div v-for="(el) of  version.versionItems" :key="el.itemId">
                                <v-card class="mt-5 px-2">
                                    <v-container class= "pt-6">
                                        <v-text-field
                                            label="Заголовок"
                                            counter="50"
                                            v-model="el.title"
                                            :rules ="checkHistory.title"
                                            variant="outlined"
                                            maxlength="50"
                                            class="mb-3"
                                        >
                                        </v-text-field>
                                        <v-text-field
                                            label="Описание"
                                            counter="100"
                                            v-model="el.description"
                                            :rules ="checkHistory.description"
                                            variant="outlined"
                                            maxlength="100"
                                            class="mb-3"
                                        >
                                        </v-text-field>
                                        <v-file-input
                                            v-if="(typeof el.file !== 'string')"
                                            show-size
                                            v-model="el.file"
                                            :rules ="checkHistory.file"
                                            label="Иконка"
                                            variant="outlined"
                                            hint="Максимальный размер изображения 100 МБ"
                                            persistent-hint
                                        >
                                        </v-file-input>
                                        <div class="d-flex justify-space-between pt-3">
                                            <div></div>
                                            <v-btn
                                            v-if="version.versionItems.length > 1"
                                            variant="flat"
                                            class="button-cancel"
                                            @click="showDealog(el.itemId)"
                                            >
                                                Удалить обновление
                                            </v-btn>
                                        </div>
                                    </v-container>
                                </v-card>
                            </div>
                        </v-form>
                    <div>
                        <v-btn
                            class="mt-5 button-action"
                            variant="flat"
                            @click="addBlock"
                        >
                            Добавить блок
                        </v-btn>
                    </div>
                </v-container>
            </div>
            <div class="version__add-right">
                <v-container>
                    <h3>Информация о версии</h3>
                    <v-form ref="version" v-model="valid" validation>
                        <div class="mt-5">
                            <v-select
                                :items="deviceType"
                                label="Платформа"
                                v-model="version.deviceType"
                                :rules ="checkHistory.platform"
                                
                                variant="outlined"
                            ></v-select>
                            <v-text-field
                                label="Новая версия"
                                v-model="version.versionNumber"
                                :rules ="checkHistory.versionNumber"
                                variant="outlined"
                            >
                            </v-text-field>
                            <v-switch
                                v-model="version.mandatoryUpdates"
                                label="Обязательное обновление"
                            ></v-switch>
                        </div>
                    </v-form>
                </v-container>
            </div>
        </div>
        <div>
            <v-dialog
                v-model="dialog.checkDelete"
                persistent
                max-width="400"
            >   
                <!-- <template v-slot:activator ></template> -->
                <DialogCheckDelete  @no = "unShowDealog" @yes = "deleteBlock"/>
            </v-dialog>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import DialogCheckDelete from '@/components/DialogCheckDelete.vue'

    export default {
        data () {
            return {
                version : {
                    description:"",
                    deviceType:"",
                    versionNumber:"",
                    mandatoryUpdates:false,
                    versionItems: [
                        {  
                            itemId:0,           
                            file: [],
                            title : "",
                            description : "",
                        },
                    ],
                    deleteVersionItem: this.deleteVersionAddPages
                },
                checkHistory : {
                    title: [v => !! v && v.length <= 50 || "Обязательное поле"],
                    description: [v => !!v && v.length <= 100 || "Обязательное поле"],
                    file: [value => !value || value.size < 104857600|| "Обязательное поле"],
                    platform : [v => !!v || "Обязательное поле"],
                    versionNumber : [v => !!v || "Обязательное поле"],
                },
                valid:true,
                validInfo:true,
                search: '',
                deviceType : ['ios', 'android'],
                long:1,
                dialog : {
                    checkDelete : false
                },
                id : {

                }
                

            } 
        },
        components : {
            DialogCheckDelete,
        },
        methods : {
            unShowDealog () {
                this.dialog.checkDelete = false;
            },
            showDealog (id) {
                this.id = id
                this.dialog.checkDelete = true;
            },
            rout() {
                this.$router.push({name:"versions"});
            },                        
            addBlock() {
                this.version.versionItems.push({     
                        itemId: this.version.versionItems[this.version.versionItems.length-1].itemId + 1,
                        title : "",
                        description:"",
                        file:[]           
                    }
                )
            },
            deleteBlock() {

                const id = this.id

                if(this.version.versionItems.length > 1) {
                    this.version.versionItems = this.version.versionItems.filter((v)=> v.itemId != id)

                    this.version.versionItems.forEach(element => {
                        element.itemId -=1;
                    });
                }
                this.unShowDealog();
            },
            async createVersions () {
                this.version.versionItems.forEach(element => {
                    window.console.log(element.file)
                })
                
                if ((await this.$refs.version.validate()).valid && (await this.$refs.validInfo.validate()).valid) {
                    
                    let formData = new FormData();

                    formData.append('deviceType',  this.version.deviceType || '');
                    formData.append('versionNumber', this.version.versionNumber || '');
                    formData.append('photo', this.version.versionItems[0].file || '');
                    formData.append('isRequired', this.version.mandatoryUpdates);
                    

                    axios.post(this.$store.getters.apiAdminPanelV3 + '/version', formData,
                    {
                        headers: {
                            'authorization': this.$store.getters.adminPanelHeaderAuth
                        }
                    })

                    .then(res => {

                        const vervionId = res.data.versionId;

                        this.version.versionItems.forEach(element => {
                            
                            let formDataItem = new FormData();

                            formDataItem.append('title',  element.title || '');
                            formDataItem.append('description', element.description || '');
                            formDataItem.append('file', element.file || '');
                            formDataItem.append('versionId', vervionId || '');
      
                            axios.post(this.$store.getters.apiAdminPanelV3 + '/version/item', formDataItem,
                            {
                                headers: {
                                    'authorization': this.$store.getters.adminPanelHeaderAuth
                                }
                            })

                            .then(res => {
                                const vervionId = res.data.versionId;
                                window.console.log(vervionId)
                                this.rout();
                            })
                            .catch(err => {
                                this.snackbar.isOpen = true
                                this.snackbar.text = err.response.data || err.stack
                            })
                        });
                    })
                    .catch(err => {
                        this.snackbar.isOpen = true
                        this.snackbar.text = err.response.data || err.stack
                    })
                } else {
                    window.console.log("Валидация не прошла")
                }
            },
        }
    }
</script>

<style lang="scss">
    .version__add {
        
        &-boby {
            max-width: 1125px;
            //width: 100%;
            //min-width: 600px !important;
        }
        &-left {
            //width: 600px;
            min-width: 475px !important;
            h4 {
                font-weight: 400;
                font-size: 20px;
            }
        }
        &-right {
            //width: 600px;

            min-width: 475px !important;
        }
        //padding-left: 9px;
        &-title {
            h2 {
                font-weight: 500;
                font-size: 20px;
                display: block;
            }
            button {
                margin-right: 22px;
            }
            margin-top: 7px;
            margin-bottom: 8px;
        }
        h3 {
            font-size: 24px !important;
            font-weight: 400 !important;
        }
        &-switch {
            padding-left: 4px;
            margin-top: 0;
            margin-bottom: 4px;
            .v-input--switch__track.primary--text {
                background-color: rgb(47,199,87) !important;
                opacity: 1;
            }
            .v-input--switch__thumb.theme--light.primary--text{
                background-color: rgb(255, 255, 255) !important;
            }
            .v-input--switch__thumb.theme--light .primary--text{
                background-color: rgb(255, 255, 255) !important;
            }
        }
        .add-btn {
            background-color: #1736cb !important;
            font-size: 15px;
            opacity: 1 ;
            i{
                font-size: 13px !important;
            }
            font-weight: 400 !important;
            border-radius: 8px;
            span {
                color: #FFFFFF!important;
                letter-spacing:normal;
            }
        }
        .delete-btn {
            background-color: transparent !important;
            box-shadow: none;
            padding: 0 !important;
            min-width: 0 !important;
            margin: 0 !important;
            .v-btn__content i {
                color: red!important;
            }
        }
        &-file {
            padding: 0;
            margin: 0;
            .v-input__prepend-outer {
                position: absolute;
            }
            .v-input__slot {
                padding-left: 30px !important;
                min-height: 0px;
            }
            .v-text-field__slot {
                margin-top: 23px;
                height: 20px;
            }
            .v-text-field--outlined .v-label--active {
                -webkit-transform: translateY(-24px) scale(0.75); 
                transform: translateY(-24px) scale(0.75); 
            }
            .lb {
                position: absolute;
                left: 43px;
                font-size: 11px;
                line-height: 3.5;
                color: rgb(0, 0, 0);
            }
        }
    }
</style>