import "@mdi/font/css/materialdesignicons.css";
import { createVuetify } from 'vuetify';
import 'vuetify/styles'
import { ru } from 'vuetify/locale'

const vuetify = createVuetify({
  locale: {
    locale: 'ru',
    messages: { ru },
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#007aff',
          error: '#ff5252',
        }
      },
    },
  },
});

export default vuetify;
