<template>
  <div class="documents">
    <v-snackbar :location="true ? 'top' : undefined" v-model="snackbar.isOpen">
      {{ snackbar.text }}
      <v-btn @click="snackbar.isOpen = false" color="red" variant="text"> Close </v-btn>
    </v-snackbar>
    <v-card class="selectLayer elevation-0">
      <v-card-title style="padding: 20px 0 20px 0 !important">
        Шаблоны Email
      </v-card-title>
      <div>
        <v-select
          variant="outlined"
          :items="mailTemplates"
          item-title="title"
          item-value="mailTemplateId"
          @update:model-value="selectMailTemplate"
          v-model="mailTemplateId"
        >
        </v-select>
      </div>
      <div>
        <v-text-field
          variant="outlined"
          label="Тема"
          v-model="mailTemplate.subject"
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          variant="outlined"
          label="Шаблон ID"
          v-model="mailTemplate.templateId"
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          variant="outlined"
          label="Имя отправителя"
          v-model="mailTemplate.fromName"
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          variant="outlined"
          label="Email отправителя"
          v-model="mailTemplate.fromEmail"
        ></v-text-field>
      </div>
    </v-card>
    <v-card class="otherLayer elevation-0">
      <v-card-text>
        <div class="mail-button">
          <v-btn
            @click="updateMailTemplateBtn()"
            variant="flat"
            class="button-action"
          >
            Сохранить
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <div class="line gray"></div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    content: null,
    snackbar: {
      isOpen: false,
      text: "",
    },
    mailTemplates: [],
    config: {
      lang: "ru",
      btns: [
        ["viewHTML"],
        ["undo", "redo"], // Only supported in Blink browsers
        ["formatting"],
        ["strong", "em", "del"],
        ["superscript", "subscript"],
        ["link"],
        ["insertImage"],
        ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
        ["unorderedList", "orderedList"],
        ["horizontalRule"],
        ["removeformat"],
      ],
    },
    mailTemplateId: "",
    mailTemplate: {},
  }),

  components: {},

  computed: {},

  watch: {},

  created() {
    this.getMailTemplates();
  },

  methods: {
    getMailTemplates() {
      this.snackbar.isOpen = false;
      axios
        .get(this.$store.getters.apiAdminPanelV1 + "/mailTemplates", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.mailTemplates = res.data;
          if (
            Array.isArray(this.mailTemplates) &&
            this.mailTemplates.length > 0
          ) {
            this.selectMailTemplate(res.data[0].mailTemplateId);
          }
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    selectMailTemplate(mailTemplateId) {
      this.mailTemplateId = mailTemplateId;

      for (const i in this.mailTemplates) {
        if (this.mailTemplateId == this.mailTemplates[i].mailTemplateId) {
          this.mailTemplate = this.mailTemplates[i];
          break;
        }
      }
    },
    updateMailTemplateBtn() {
      this.snackbar.isOpen = false;
      axios
        .put(
          this.$store.getters.apiAdminPanelV1 + "/mailTemplate",
          this.mailTemplate,
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          this.snackbar.isOpen = true;
          this.snackbar.text = "Сохранено";
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
  },
};
</script>

<style>
.selectLayer {
  padding: 0 20px 0 20px;
  z-index: 1;
}

.otherLayer {
  padding: 0 20px 0 20px;
  z-index: 2;
}
.mail-button {
  display: flex;
  justify-content: flex-end;
}
</style>
